<template>
    <div id="production" :class="[$mq]">
        <div id="sidebar">
            <Sidebar :categories="categories" :date="date" :summary="summary" @selectDate="selectDate" @selectCategory="selectCategory" :expanded="forecast && !configuration"></Sidebar>
        </div>

        <div id="board">
            <div class="main-content">
                <ProductionConfiguration v-if="configuration" />
                <ProductionInit :date="date" v-else-if="!forecast"></ProductionInit>

                <div class="table" v-else>
                    <div :class="['topbar', { expanded: isExpanded }]">
                        <div class="sales-information">
                            <button class="nav sales-button" :class="{ active: isExpanded }" @click="toggleSalesSection"></button>
                            <button class="nav refresh" @click="refresh"></button>
                        </div>
                        <div class="content">
                            <div class="navBar">
                                <button class="nav left" @click="scrollLeft"></button>
                                <div class="hours" ref="scrollContainer1">
                                    <div v-for="(subValue, subKey) in hoursForecast" :key="subKey" :class="{ 'scroll-item': true, selected: timeConvertor(subKey) === timeConvertor(closestTime) }">
                                        <p>{{ timeConvertor(subKey) }}</p>
                                    </div>
                                </div>
                                <button class="nav right" @click="scrollRight"></button>
                            </div>
                            <div class="summary-title">
                                <span>{{ $t('production.summary.title') }} </span>
                            </div>
                        </div>
                    </div>
                    <div v-if="isExpanded" class="sales-section">
                        <div class="sales-text">
                            <span> {{ $t('production.sales.projected') }}</span>
                            <span> {{ $t('production.sales.real') }}</span>
                            <span> {{ $t('production.sales.difference') }}</span>
                            <span> {{ $t('production.sales.percentage_difference') }}</span>
                        </div>
                        <div class="sales-content">
                            <div v-if="forecast.sales_forecast" class="sales-forecast" ref="scrollContainer3">
                                <div class="row sales-forecast-projected">
                                    <div v-for="value in summary_hours.forecast" class="scroll-item">
                                        <span> {{ value }}€ </span>
                                    </div>
                                </div>
                                <div class="row sales-forecast-real">
                                    <div v-for="value in summary_hours.actual" class="scroll-item">
                                        <span v-if="value"> {{ value }}€ </span>
                                        <span v-else>-</span>
                                    </div>
                                </div>
                                <div class="row sales-forecast-difference">
                                    <div v-for="value in summary_hours.diff" class="scroll-item">
                                        <span v-if="value" :class="{ positive: value > 0, negative: value < 0 }"> {{ value }}€ </span>
                                        <span v-else>-</span>
                                    </div>
                                </div>
                                <div class="row sales-forecast-difference-percentatge">
                                    <div v-for="value in summary_hours.diff_delta" class="scroll-item">
                                        <span v-if="value" :class="{ positive: value > 0, negative: value < 0 }"> {{ value }}% </span>
                                        <span v-else>-</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-if="forecast.sales_forecast" class="sales-summary">
                            <span>{{ summary.forecast }} €</span>
                            <span>{{ summary ? summary.current_sales : 0 }} €</span>
                            <span :class="{ positive: summary.diff > 0, negative: summary.diff < 0 }"> {{ summary ? summary.diff : '-' }} € </span>
                            <span :class="{ positive: summary.current_delta > 0, negative: summary.current_delta < 0 }"> {{ summary.current_delta ? summary.current_delta : 0 }}%</span>
                        </div>
                    </div>
                    <div class="product-content">
                        <div class="products">
                            <div class="item" v-for="product in products" :key="product.id" @click="showProductInfo(product)">
                                <div class="icon" :style="{ backgroundImage: 'url(' + product.image + ')' }"></div>
                                <div class="content">
                                    <div class="name">{{ product.name }}</div>
                                    <div class="container">{{ product.containers[product.active_container] ? product.containers[product.active_container].name : '' }}</div>
                                </div>
                            </div>
                        </div>
                        <div class="content">
                            <div class="forecast">
                                <div class="item" v-for="product in products" :key="product.id" ref="scrollContainer">
                                    <div v-for="item in combinedData[product.id]" :key="item.date" class="scroll-item" :class="{ current: item.date == current_hour }">
                                        <span class="value">{{ item.forecast }}</span>
                                        <!--current_hour<span class="increase" :class="{ positive: cell.i > 0, negative: cell.i < 0 }">{{ cell.i ? Math.abs(cell.i) : '-' }}</span> -->
                                    </div>
                                </div>
                            </div>
                            <div class="summary">
                                <div class="item" v-for="product in products" :key="product.id">
                                    <span class="value"> {{ getTotal(product.id) }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Sidebar from '../components/domain/production/sidebar.vue'
import ProductionConfiguration from '../components/domain/production/ProductionConfiguration.vue'
import ProductionInit from '../components/domain/production/ProductionInit.vue'

export default {
    components: {
        Sidebar,
        ProductionConfiguration,
        ProductionInit
    },
    name: 'Production',
    data() {
        return {
            timers: [],
            selectedCategory: false,
            date: moment().format('YYYY-MM-DD'),
            current_time: 2,
            visible_time: [0, 6],
            closestTime: null,
            closestKey: null,
            isExpanded: false
        }
    },
    computed: {
        categories() {
            return this.$store.getters['production/getCategories']
        },
        category() {
            return this.$store.getters['production/getSelectedCategory']
        },
        products() {
            let data = this.$store.getters['production/getProducts']
            let products = []
            for (var idx in data) {
                var product = data[idx]
                let show = false
                if (!this.category) {
                    show = true
                } else if (this.category && product.category == this.category.id) {
                    show = true
                }

                if (show && product.status) {
                    products.push(product)
                }
            }
            return products
        },
        forecast() {
            return this.$store.getters['production/getForecast']
        },
        summary() {
            if (this.forecast.summary && this.forecast.sales_forecast) {
                var current_level = false
                var current_forecast = 0

                if (true || (moment(this.date).isSame(moment(), 'day') && this.forecast.pmix_real && this.forecast.pmix_real.levels)) {
                    var key = false
                    if (moment(this.date).isSame(moment(), 'day')) {
                        key = moment(Math.floor(moment().unix() / 1800) * 1800 * 1000).format('YYYY-MM-DD HH:mm:ss')
                    } else {
                        key = moment(Math.floor(moment(this.date).add('days', 1).unix() / 1800) * 1800 * 1000).format('YYYY-MM-DD HH:mm:ss')
                    }

                    for (var idx in this.forecast.sales_forecast.general.sales_forecast) {
                        if (idx < key) {
                            current_forecast += this.forecast.sales_forecast.general.sales_forecast[idx]
                            current_forecast += this.forecast.sales_forecast.delivery.sales_forecast[idx]
                        }
                    }

                    current_forecast = Math.round(current_forecast, 2)
                }

                var current_sales = this.forecast.sales_real ? this.forecast.sales_real.general.total : 0

                return {
                    date: moment(this.forecast.summary.ref_general).isValid() ? moment(this.forecast.summary.ref_general).format('DD/MM/YYYY') : this.forecast.summary.ref_general,
                    date_delivery: moment(this.forecast.summary.ref_delivery).isValid() ? moment(this.forecast.summary.ref_delivery).format('DD/MM/YYYY') : this.forecast.summary.ref_delivery,
                    base: this.forecast.summary.sales_base,
                    forecast: Math.round(this.forecast.summary.total),
                    current_forecast: current_forecast,
                    current_sales: current_sales,
                    diff: current_sales - current_forecast,
                    current: this.forecast.sales_real ? this.forecast.sales_real.general.total : 0,
                    current_delta: Math.round(((current_sales - current_forecast) / current_forecast) * 100)
                }
            }
            return {}
        },
        current_hour() {
            var key = false
            if (moment(this.date).isSame(moment(), 'day')) {
                key = moment(Math.floor(moment().unix() / 1800) * 1800 * 1000).format('YYYY-MM-DD HH:mm:ss')
            } else {
                key = moment(Math.floor(moment(this.date).add('days', 1).unix() / 1800) * 1800 * 1000).format('YYYY-MM-DD HH:mm:ss')
            }
            return key
        },
        summary_hours() {
            var result = {
                forecast: [],
                actual: [],
                diff: [],
                diff_delta: []
            }

            var key = false
            if (moment(this.date).isSame(moment(), 'day')) {
                key = moment(Math.floor(moment().unix() / 1800) * 1800 * 1000).format('YYYY-MM-DD HH:mm:ss')
            } else {
                key = moment(Math.floor(moment(this.date).add('days', 1).unix() / 1800) * 1800 * 1000).format('YYYY-MM-DD HH:mm:ss')
            }

            if (this.forecast.summary && this.forecast.sales_forecast) {
                for (var idx in this.forecast.sales_forecast.general.sales_forecast) {
                    var forecast = Math.round(this.forecast.sales_forecast.general.sales_forecast[idx] + this.forecast.sales_forecast.delivery.sales_forecast[idx])
                    var current = false
                    var diff = false
                    var diff_delta = false

                    if (idx < key) {
                        current = this.forecast.sales_real ? this.forecast.sales_real.general[idx] : 0
                        diff = current - forecast
                        diff_delta = (diff / forecast) * 100
                    }

                    result.forecast.push(forecast)
                    result.actual.push(current)
                    result.diff.push(diff)
                    result.diff_delta.push(diff_delta)
                }
            }

            return result
        },
        configuration() {
            return this.$store.getters['production/getConfiguration']
        },
        hoursForecast() {
            if (this.forecast.sales_forecast != null) {
                return this.forecast.sales_forecast.general.sales_forecast || {}
            }
            return {}
        },
        combinedData() {
            if (!this.forecast || !this.forecast.products) {
                return {}
            }
            const combinedData = {}
            const hoursForecast = this.hoursForecast

            this.products.forEach((product) => {
                const forecast = this.forecast.products[product.id] || {}
                combinedData[product.id] = []
                var units = product.containers[product.active_container].units

                for (const date in hoursForecast) {
                    var key = moment(date).format('HH:mm')
                    const factor = 0.25
                    combinedData[product.id].push({
                        date: date,
                        hoursForecast: hoursForecast[date],
                        forecast: forecast[key] !== undefined ? Math.ceil(forecast[key] / units / factor) * factor : '-'
                    })
                }
            })

            // for (var product_id in this.forecast.products) {
            //     var product = this.forecast.products[product_id]
            //     combinedData[product.id] = []

            //     for (var date in product) {
            //         combinedData[product.id].push({
            //             date: date,
            //             hoursForecast: false,
            //             forecast: product[date]
            //         })
            //     }
            // }

            return combinedData
        }
    },
    methods: {
        load() {
            let self = this

            if (this.products.length == 0) {
                this.$overlay.loading()
                this.$store.dispatch('production/loadData').then(() => {
                    self.selectCategory(false)
                    self.$overlay.hide()
                    this.loadForecast()
                })
            } else {
                this.loadForecast()
            }
        },
        loadForecast() {
            let self = this
            this.$overlay.loading()
            this.$store.dispatch('production/loadForecast', this.date).then(() => {
                self.$overlay.hide()
                self.calculateClosestHour()
                self.scrollToClosestHour()
            })
        },
        selectCategory(category) {
            var self = this
            self.$overlay.loading()
            this.$store.commit('production/selectCategory', category)
            self.selectedCategory = category
            self.$overlay.hide()
        },
        selectDate(date) {
            this.date = moment(date).format('YYYY-MM-DD')
            this.loadForecast()
        },
        timeConvertor(date) {
            return moment(date).format('HH:mm')
        },
        scrollLeft() {
            this.scrollContainers(-130)
        },
        scrollRight() {
            this.scrollContainers(130)
        },
        scrollContainers(offset) {
            this.$refs.scrollContainer.forEach((container) => {
                container.scrollBy({ left: offset, behavior: 'smooth' })
            })
            this.$refs.scrollContainer1.scrollBy({ left: offset, behavior: 'smooth' })
            if (this.$refs.scrollContainer3) {
                this.$refs.scrollContainer3.scrollBy({ left: offset, behavior: 'smooth' })
            }
        },
        calculateClosestHour() {
            const nowMinutes = new Date().getMinutes()
            const nowHours = new Date().getHours()

            let closestTime
            if (nowMinutes < 30) {
                closestTime = new Date(new Date().setHours(nowHours, 0, 0, 0))
            } else {
                closestTime = new Date(new Date().setMinutes(30, 0, 0))
            }

            this.closestTime = closestTime
            for (let key in this.hoursForecast) {
                const forecastDate = new Date(key)
                if (forecastDate <= closestTime) {
                    this.closestKey = key
                } else {
                    break
                }
            }
        },
        scrollToClosestHour() {
            const scrollItemIndex = Object.keys(this.hoursForecast).findIndex((key) => key === this.closestKey)
            if (scrollItemIndex !== -1) {
                const scrollOffset = scrollItemIndex * 120
                this.$refs.scrollContainer.forEach((container) => {
                    container.scrollTo({ left: scrollOffset, behavior: 'smooth' })
                })
                this.$refs.scrollContainer1.scrollTo({ left: scrollOffset, behavior: 'smooth' })
                this.$refs.scrollContainer3.scrollTo({ left: scrollOffset, behavior: 'smooth' })
            }
        },
        productForecast(productId) {
            for (let id in this.forecast.products) {
                if (id == productId) {
                    return this.forecast.products[id]
                }
            }
            return null
        },
        getTotal(productId) {
            var product = this.products.find((item) => item.id === productId)
            var units = product.containers[product.active_container].units
            var factor = 0.25

            if (Object.keys(this.forecast).length > 0) {
                return Math.ceil(this.forecast.products[productId].total / units / factor) * factor
            }
            return null
        },
        toggleSalesSection() {
            this.isExpanded = !this.isExpanded
            var scrollTo = this.$refs.scrollContainer[0].scrollLeft
            this.$nextTick(() => {
                this.$refs.scrollContainer3.scrollTo({ left: scrollTo, behavior: 'smooth' })
            })
        },
        refresh() {
            this.isExpanded = false
            this.loadForecast()
        },
        showProductInfo(product) {
            var totalProduction = this.calculateTotalForecast(product)
            this.$popup.productInfo({
                productName: product.name,
                productContainer:  product.containers[product.active_container] ? product.containers[product.active_container].name : '',
                productIcon: product.image,
                projectedProduction: this.getTotal(product.id),
                realProduction: totalProduction,
                remainingProduction: totalProduction - this.getTotal(product.id),
                projectedProductionDifference: '-',
                realProductionDifference: '-'
            })
        },
        calculateTotalForecast(product) {
            if (!this.forecast || !this.forecast.products) {
                return 0
            }
            const forecast = this.forecast.products[product.id] || {}
            const hoursForecast = this.hoursForecast
            var units = product.containers[product.active_container].units
            var totalForecast = 0

            for (const date in hoursForecast) {
                var key = moment(date).format('HH:mm')
                const factor = 0.25
                const forecastValue = forecast[key] !== undefined ? Math.ceil(forecast[key] / units / factor) * factor : 0
                if (moment(date).isSameOrBefore(this.closestTime)) {
                    totalForecast += forecastValue
                }
            }

            return totalForecast
        }
    },
    created() {
        this.load()
    },
    mounted() {},
    watch: {}
}
</script>

<style lang="scss" scoped>
#production {
    display: flex;
    x-justify-content: space-around;
    x-align-items: stretch;
    width: 100%;

    #sidebar {
        width: 325px;
        flex: 0 0 325px;
        height: 100%;
        background-color: #fff;

        position: absolute;
    }
    #board {
        padding: 16px;
        border-radius: 4px;
        width: calc(100% - 325px);
        flex: 0 0 calc(100% - 325px);
        position: absolute;
        left: 325px;
        height: 100%;
        min-height: 0;
        min-width: 0;

        .main-content {
            width: 100%;
            height: 100%;
            overflow-y: auto;
            // position: absolute;
            // height: calc(100% - 50px - 16px);

            .table {
                display: flex;
                flex-direction: column;

                .positive {
                    font-family: $text-bold !important;
                    color: $color-success-500;
                }
                .negative {
                    font-family: $text-bold !important;
                    color: $color-error-500;
                }

                .topbar {
                    width: 100%;
                    height: 50px;
                    display: flex;
                    align-items: center;
                    overflow: hidden;
                    margin-bottom: 25px;

                    .content {
                        display: flex;
                        width: 70%;
                        justify-content: flex-end;
                    }

                    button {
                        height: 50px;
                        width: 50px;
                        font-size: 30px;
                        background-color: #fff;
                    }
                    .sales-information {
                        width: 30%;
                        display: flex;
                        flex-direction: row;
                        justify-content: left;
                        align-items: center;
                        flex-shrink: 0;
                        height: 50px;
                        gap: 8px;

                        .nav {
                            border-radius: 4px;
                            display: flex;
                            align-self: flex-start;
                            width: 50px;
                            height: 50px;
                            align-items: center;
                            &.sales-button {
                                @include background($image: img('chart_inactive.svg'), $size: 20px, $color: #fff);
                            }
                            &.active {
                                &.sales-button {
                                    @include background($image: img('chart_active.svg'), $size: 20px, $color: $main);
                                }
                            }
                            &.refresh {
                                @include background($image: img('reload.svg'), $size: 20px, $color: #fff);
                            }
                        }
                    }
                    .navBar {
                        width: calc(100% - 100px);
                        display: flex;
                        justify-content: space-between;
                        align-items: stretch;
                        padding-left: 75px;

                        .nav {
                            border-radius: 4px;
                            width: 50px;
                            height: 50px;

                            &.left {
                                @include background($image: img('left_dark.svg'), $size: 20px, $color: #fff);
                            }
                            &.right {
                                @include background($image: img('right_dark.svg'), $size: 20px, $color: #fff);
                            }
                        }
                        .hours {
                            width: calc(100% - 100px);
                            height: 50px;
                            display: flex;
                            flex-direction: row;
                            align-items: flex-end;
                            overflow-x: hidden;
                            scroll-behavior: smooth;
                            gap: 25px;
                            padding: 0 0px;

                            .scroll-item {
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                flex: 0 0 80px;
                                height: 100%;
                                width: 100px;
                                max-width: 100px;
                                min-width: 100px;
                                margin-left: 5px;

                                p {
                                    font-family: $text;
                                    font-size: 20px;
                                    color: $color-neutral-600;
                                }
                            }
                            & .selected {
                                border: 2px solid $color-warning-500;
                                p {
                                    font-family: $text-bold;
                                }
                            }
                        }
                    }
                    .summary-title {
                        text-align: center;
                        width: 125px;
                        flex-shrink: 0;

                        span {
                            word-wrap: break-word;
                            @include font-size(m);
                            color: $color-neutral-600;
                            font-family: $text-bold;
                            text-transform: uppercase;
                        }
                    }
                }
                .sales-section {
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                    .sales-text {
                        width: 30%;
                        padding-left: 60px;
                        span {
                            display: block;
                            font-family: $text;
                            display: flex;
                            align-items: center;
                            height: 35px;
                        }
                    }
                    .sales-content {
                        width: 70%;
                        display: flex;
                        flex-direction: row;
                        justify-content: flex-end;
                        box-sizing: border-box;
                        overflow: hidden;
                        .sales-forecast {
                            width: calc(100% - 185px);
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            overflow-x: hidden;
                            overflow-y: hidden;
                            scroll-behavior: smooth;
                            margin-right: 50px;

                            .row {
                                display: flex;
                                flex-direction: row;
                                gap: 25px;
                                width: 100%;

                                .scroll-item {
                                    flex: 0 0 80px;
                                    height: 35px;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    width: 100px;
                                    max-width: 100px;
                                    min-width: 100px;
                                    margin-left: 5px;

                                    span {
                                        text-align: center;
                                        width: 100px;
                                        font-family: $text;

                                        &.positive {
                                            font-family: $text-bold;
                                            color: $color-success-500;
                                        }
                                        &.negative {
                                            font-family: $text-bold;
                                            color: $color-error-500;
                                        }
                                    }
                                }
                            }
                        }
                    }
                    .sales-summary {
                        width: 125px;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        flex-shrink: 0;
                        span {
                            font-family: $text;
                            display: flex;
                            align-items: center;
                            height: 35px;
                        }
                    }
                }
                .product-content {
                    display: flex;
                    flex-direction: row;
                    width: 100%;
                    margin-top: 16px;

                    .products {
                        width: 30%;

                        .item {
                            width: 100%;
                            height: 75px;
                            display: flex;
                            align-items: center;
                            cursor: pointer;

                            &:hover {
                                background-color: rgba(255, 255, 255, 0.6);
                            }

                            .icon {
                                width: 50px;
                                height: 50px;
                                background-repeat: no-repeat;
                                background-position: center;
                                background-size: 90%;
                                display: inline-block;
                            }
                            .content {
                                flex-direction: column;
                                width: 100%;
                                padding-left: 8px;
                                .name {
                                    display: block;
                                    font-family: $text-bold;
                                    text-transform: uppercase;
                                    @include font-size(m);
                                }
                                .container {
                                    display: block;
                                    font-family: $text;
                                    color: $color-neutral-600;
                                    display: block;
                                }
                            }
                        }
                    }

                    .content {
                        display: flex;
                        width: 70%;
                        justify-content: flex-end;

                        .forecast {
                            width: calc(100% - 125px);
                            display: flex;
                            flex-direction: column;
                            align-content: center;
                            align-items: stretch;
                            padding: 0 50px;

                            .item {
                                height: 75px;
                                display: flex;
                                flex-direction: row;
                                align-items: center;
                                overflow-x: hidden;
                                scroll-behavior: smooth;
                                margin-left: 50px;
                                padding: 0;
                                gap: 25px;
                                .scroll-item {
                                    flex: 0 0 80px;
                                    height: 60px;
                                    background: #fff;
                                    border: 1px solid #ddd;
                                    border-radius: 3px;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    flex-direction: column;
                                    position: relative;
                                    margin-left: 5px;
                                    width: 100px;
                                    max-width: 100px;
                                    min-width: 100px;
                                    // width: 100px;
                                    // min-width: 100px;

                                    .value {
                                        font-family: $text;
                                        @include font-size(m);
                                        display: block;
                                    }
                                    .increase {
                                        display: block;
                                        position: absolute;
                                        bottom: 0px;
                                        @include font-size(xs);
                                        display: flex;
                                        align-items: center;

                                        &:before {
                                            display: inline-block;
                                            content: '';
                                            width: 0;
                                            height: 0;
                                            border-left: 5px solid transparent;
                                            border-right: 5px solid transparent;
                                            border-radius: 3px;
                                            margin-right: 2px;
                                        }

                                        &.positive {
                                            &:before {
                                                border-bottom: 6px solid green;
                                            }
                                        }
                                        &.negative {
                                            &:before {
                                                border-top: 6px solid #f00;
                                            }
                                        }
                                    }

                                    &.current {
                                        background-color: $color-warning-500;
                                        border-color: $color-warning-500;
                                        font-family: $text-bold;
                                        color: #fff;

                                        .value {
                                            color: #fff;
                                        }
                                        .increase {
                                            color: #fff;
                                        }
                                    }
                                }
                            }
                        }
                    }
                    .summary {
                        width: 125px;
                        text-align: center;
                        .item {
                            width: 100%;
                            height: 75px;
                            display: flex;
                            align-items: center;
                            justify-content: space-around;

                            .value {
                                font-family: $text-bold;
                                @include font-size(l);
                                text-transform: uppercase;
                                text-align: center;
                            }
                        }
                    }
                }
            }
        }
    }
}
</style>
